<template lang="pug">
  .process
    v-container(v-if="!formSubmitted && !confirmed && !success")
      v-form(ref="form", v-model="valid", @submit.prevent="submitForm")
        v-layout(wrap)
          v-flex.process__title(sm10, offset-sm2) Send tokens
        v-layout.process__block(wrap)
          v-flex.process__side(sm2) Tokens
          v-flex(sm10)
            .process__input
              v-select.main_text_input(
                label="Currency",
                v-model="selectedAccount",
                :items="accounts",
                item-text="currencyName",
                item-value="currencyCode",
                :rules="[rules.required]",
                append-icon="keyboard_arrow_down",
                @change="onCurrencyChange",
                outline)
                template(slot="selection" slot-scope="data")
                  CoinIcon(:name="data.item.currencyCode")
                  .selection {{ data.item.currencyName }}
                template(slot="item" slot-scope="data")
                  CoinIcon(:name="data.item.currencyCode")
                  v-list-item-content
                    v-list-item-title(v-html="data.item.currencyName")
              .process__input__value
                .origin {{ getAvailableBalance(selectedAccount) | currency($i18n.locale) }} {{ selectedAccount }}
                .base ≈ {{ getAvailableBalanceInUserCurrency(selectedAccount).toFixed(2) | currency($i18n.locale) }} {{ baseCurrencyCode }}
            .process__input
              v-text-field.main_text_input.input__currency(
                v-model.trim="send[amountName]",
                :rules="[rules.required, rules.amount, rules.minAmount, rules.maxAmount]",
                label="Tokens amount",
                :hint="amountHint",
                persistent-hint,
                @input="amountWidth = 0"
                @change="onAmountInputChange",
                ref="amountInput",
                outline)
              .process__input__suffix(
                v-if="send.amount",
                :class="{ active: amountWidth }",
                :style="{'left': `${amountWidth}px` }") {{ displayCurrency }}
              .process__input__value.currencies
                span(
                  :class="{ active: displayCurrency === selectedAccount }",
                  @click="displayCurrency = selectedAccount") {{ selectedAccount }}
                span(
                  :class="{ active: displayCurrency === baseCurrencyCode }",
                  @click="displayCurrency = baseCurrencyCode") {{ baseCurrencyCode }}
        v-layout.process__block(wrap)
          v-flex.process__side(sm2) Recipient
          v-flex(sm10)
            .process__input
              v-combobox.main_text_input.combobox(
                v-model="recipient",
                :items="kickUsers",
                item-text="fullName",
                item-value="id",
                :search-input.sync="search",
                :loading="isLoadingUsers",
                :rules="[rules.required, rules.walletAddress]",
                @change="onRecipientChange",
                label="Kick ID or wallet address",
                :hint="recipientHint",
                persistent-hint,
                single-line,
                outline)
                .combobox__empty(slot="no-data") Wallet address: {{ search }}
                .combobox__selected(slot="selection", slot-scope="{ item, parent, selected }")
                  .combobox__user(v-if="item === Object(item)")
                    .combobox__avatar(v-if="item.avatar")
                      img(:src="item.avatar ")
                    AvatarPlaceholder.sm(v-else, :fullName="item.fullName")
                    .combobox__name {{ item.fullName }} #[span {{ lastVisit(item.lastSession) }}]
                  .combobox__wallet(v-else) {{ item }}
                .combobox__userlist(slot="item", slot-scope="{ item }")
                  .combobox__user
                    .combobox__avatar(v-if="item.avatar")
                      img(:src="item.avatar ")
                    AvatarPlaceholder.sm(v-else, :fullName="item.fullName")
                    .combobox__name {{ item.fullName }} #[span {{ lastVisit(item.lastSession) }}]
              .process__input
                v-text-field.main_text_input(
                  v-if="send.onchain && fee && valid",
                  label="Network fee",
                  v-model="fee",
                  :hint="feeHint",
                  persistent-hint,
                  readonly,
                  outline)
        v-layout.process__block(wrap)
          v-flex.process__title(sm10, offset-sm2)
            v-btn.main_button(:disabled="!valid", @click="submitForm") Continue
        v-progress-linear(indeterminate, v-if="loading", height="3", color="#19a2dc")
    v-container(v-if="formSubmitted && !confirmed && !success")
      v-layout(wrap)
        v-flex.process__title(sm10, offset-sm2) Confirm transaction
      v-layout.process__block(wrap)
        v-flex.process__side(sm2) Transaction
        v-flex(sm10)
          .process__confirm
            .process__confirm__currency {{ send.amount }} {{ selectedAccount }}
            div From {{ getWalletAddress(selectedAccount) }}
            div To {{ recipient.fullName || recipient }}
            div(v-if="send.onchain") Network fee {{ fee }} {{ selectedAccount }}
            .process__confirm__edit(@click="editTransaction") Edit
      v-layout.process__block(wrap)
        v-flex.process__side(sm2) Total
        v-flex.process__confirm__total(sm10)
          .process__confirm__currency {{ send.amount }} {{ selectedAccount }}
          div(v-if="totalHint") {{ totalHint }}
          .process__confirm__rate(v-if="selectedAccount !== baseCurrencyCode") Current Rate: 1 {{ selectedAccount }} = {{ getRate(selectedAccount) | currency($i18n.locale) }} {{ baseCurrencyCode }}
      v-layout.process__block(wrap)
        v-flex.process__title(sm6, offset-sm2)
          v-btn.main_button(@click="confirmTransaction", :disabled="loading") Confirm
      v-layout.process__block(v-if="transactionError")
        v-flex.process__block(sm6, offset-sm2)
          .process__error #[strong Error:] {{ transactionError }}
    //- v-container(v-if="formSubmitted && confirmed && !success")
    //-   v-layout(justify-center)
    //-     v-flex.verification
    //-       .process__title Enter the verification code
    //-       v-layout.process_verification(wrap)
    //-         SecureCodeInputs(v-model="verificationCode", :blurOnComplete="true")
    //-       .process__error(v-if="transactionError") #[strong Error:] {{ transactionError }}
    //-   v-progress-linear(indeterminate, v-if="loading", height="3", color="#19a2dc")
    v-container(v-if="formSubmitted && success")
      v-layout.transaction__success(justify-center)
        v-flex.transaction__success__block
          .process__title Transaction successfully created!
          .transaction__info #[span Transaction ID] {{ transactionHash }}
          v-layout(v-if="send.onchain")
            .transaction__info #[span Block]
              .transaction__block(v-if="block") {{ block }}
              .loader(v-else)
                span.loader__dot(v-for="index in 3", :key="index") .
            .transaction__info #[span Network fee] {{ fee | currency($i18n.locale) }} {{ selectedAccount }}
          .transaction__kick(v-else)
            v-layout.transaction__offchain(fill-height)
              v-flex.transaction__offchain__block
                v-layout(column, justify-space-between, fill-height)
                  .transaction__info #[span Recipient]
                    .combobox__user
                      .combobox__avatar(v-if="recipient.avatar")
                        img(:src="recipient.avatar")
                      AvatarPlaceholder.sm(v-else, :fullName="recipient.fullName")
                      .combobox__name {{ recipient.fullName }} #[span {{ lastVisit(recipient.lastSession) }}]
                  .transaction__info #[span Status] Confirmed on {{ send.confirmed | d($i18n.locale) }}
                  v-btn.main_button.transaction__btn(@click="redirectToTransactions") See my transactions
              v-flex
                .transaction__achievement(:style="{ 'background-image': 'url(' + achievement1 + ')' }") Achievement unlocked
                  .transaction__achievement__title Democracy support
                  router-link.transaction__achievement__link(to="profile") All rewards
          v-layout.transaction__btn__block(v-if="send.onchain", justify-space-between, align-center)
            v-btn.main_button.transaction__btn(@click="redirectToTransactions") See my transactions
            .etherscan__link(v-if="selectedAccount === 'BTC'")
              a(:href="btcLink", target="_blank") Go to bitaps.com for more details
              SvgArrowRight
            .etherscan__link(v-if="selectedAccount === 'BCH'")
              a(:href="bchLink", target="_blank") Go to bitcoin.com for more details
              SvgArrowRight
            .etherscan__link(v-else-if="selectedAccount === 'USDT'")
              a(:href="omniexplorerLink", target="_blank") Go to Omni Explorer for more details
              SvgArrowRight
            .etherscan__link(v-else-if="selectedAccount === 'LTC'")
              a(:href="ltcLink", target="_blank") Go to bitaps.com for more details
              SvgArrowRight
            .etherscan__link(v-else-if="selectedAccount === 'TRX'")
              a(:href="trxLink", target="_blank") Go to TRONscan for more details
              SvgArrowRight
            .etherscan__link(v-else)
              .etherscan__link__logo(:style="{ 'background-image': 'url(' + etherscanLogo + ')' }")
              a(:href="etherscanLink", target="_blank") Go to Etherscan for more details
              SvgArrowRight
</template>

<script>
import SecureCodeInputs from 'ofcold-security-code';
import REGEXP from '@/regexp';
import userMan from '@/assets/dev/userMan2.jpg';
import achievement1 from '@/assets/achievement1.png';
import AvatarPlaceholder from '@/components/AvatarPlaceholder';
import etherscanLogo from '@/assets/etherscanLogo.png';
import SvgArrowRight from '@/components/svg/SvgArrowRight';
import CoinIcon from '@/components/CoinIcon';
import worker from '@/worker';
import { generateId } from '@/utils';

export default {
  components: {
    AvatarPlaceholder, SvgArrowRight, SecureCodeInputs, CoinIcon
  },
  props: {
    selected: String,
    baseCurrencyCode: { type: String, required: true },
    reset: { type: Boolean, default: false }
  },
  data() {
    return {
      etherscanLogo,
      achievement1,
      transactionHash: '',
      transactionError: '',
      block: '',
      valid: false,
      loading: false,
      selectedAccount: this.selected,
      displayCurrency: this.selected,
      minAmount: {
        BTC: 0,
        BCH: 0,
        KICK: 0,
        USD: 0,
        ETH: 0,
        USDT: 0,
        WRL: 0,
        LTC: 0,
        TRX: 0
        // XRP: 0.025
      },
      formSubmitted: false,
      confirmed: false,
      success: false,
      amountWidth: 0,
      kickUsers: [],
      recipient: '',
      fee: 0,
      search: null,
      isLoadingUsers: false,
      send: {
        amount: '',
        amountInBaseCurrency: '',
        currency: 'KICK',
        onchain: true
      },
      verificationCode: '',
      rules: {
        max: v => v.trim().length <= 255 || 'This field should not be more than 255 characters length',
        amount: v => REGEXP.float.test(v) || REGEXP.integer.test(v) || 'This value should be integer or float',
        minAmount: v => {
          const min = this.minAmount[this.selectedAccount];

          return parseInt(v, 10) > min
            || parseFloat(v) > min
            || `Amount of the transaction should be more than ${min} ${this.selectedAccount}`;
        },
        maxAmount: v => {
          let max = this.getAvailableBalance(this.selectedAccount);

          if (this.displayCurrency === this.baseCurrencyCode) max *= this.getRate(this.selectedAccount);

          return parseInt(v, 10) <= max
            || parseFloat(v) <= max
            || `Amount of the transaction cannot be more than ${max} ${this.displayCurrency}`;
        },
        required: v => !!v || 'Required.',
        walletAddress: v => {
          // TODO: fix validator
          if (v.length) return !!v;
          return true;
          // if (this.selectedAccount === 'BTC') return REGEXP.addressBTC.test(v) || 'This doesn\'t seem to be correct format for a BTC address';
          // return REGEXP.addressETH.test(v) || 'This doesn\'t seem to be correct format for an address';
        }
      },
      mockUsers: [
        {
          id: 1,
          fullName: 'Cecilia Miles',
          avatar: userMan,
          verified: true,
          online: true,
          lastSession: '2019-01-15T00:15:00Z',
          rating: 2130,
          following: true
        },
        {
          id: 2,
          fullName: 'Rolanda Dixon',
          avatar: '',
          verified: false,
          online: false,
          lastSession: '2018-05-09T12:55:00Z',
          rating: 140,
          following: false
        },
        {
          id: 3,
          fullName: 'Mark Walberg',
          avatar: userMan,
          verified: false,
          online: false,
          lastSession: '2019-01-25T12:35:00Z',
          rating: 2345,
          following: true
        },
        {
          id: 4,
          fullName: 'Wilfred',
          avatar: '',
          verified: true,
          online: false,
          lastSession: '2019-01-12T11:15:00Z',
          rating: 78,
          following: true
        },
        {
          id: 5,
          fullName: 'Roland Dixon',
          avatar: userMan,
          verified: false,
          online: true,
          lastSession: '2019-01-24T13:15:00Z',
          rating: 564,
          following: false
        },
        {
          id: 6,
          fullName: 'Mark Wilkerson',
          avatar: '',
          verified: false,
          online: false,
          lastSession: '2019-01-25T12:35:00Z',
          rating: 2345,
          following: true
        },
        {
          id: 7,
          fullName: 'Wilfred Fitzgerald',
          avatar: userMan,
          verified: true,
          online: false,
          lastSession: '2019-01-12T11:15:00Z',
          rating: 78,
          following: true
        },
        {
          id: 8,
          fullName: 'Roland Dixon',
          avatar: '',
          verified: false,
          online: true,
          lastSession: '2019-01-24T13:15:00Z',
          rating: 564,
          following: false
        }
      ]
    };
  },
  methods: {
    getAvailableBalance(currencyCode) {
      return this.$store.getters['user/getAvailableBalance'](currencyCode);
    },
    getRate(currencyCode) {
      return this.$store.state.user.rates[currencyCode];
    },
    getAvailableBalanceInUserCurrency(currencyCode) {
      const rate = this.getRate(currencyCode);

      return this.getAvailableBalance(currencyCode) * rate;
    },
    onCurrencyChange(val) {
      this.displayCurrency = val;
      this.$refs.amountInput.validate();
      this.getCurrencyCommission();
    },
    submitForm() {
      if (this.$refs.form.validate()) this.formSubmitted = true;
    },
    onAmountInputChange(value) {
      if (this.$refs.amountInput.validate()) {
        this.getAmountSuffix(value);

        if (this.displayCurrency === this.baseCurrencyCode) {
          const amount = value / this.getRate(this.selectedAccount);
          this.send.amount = Number.isInteger(amount) ? amount : amount.toFixed(9);
        } else {
          const amount = value * this.getRate(this.selectedAccount);
          this.send.amountInBaseCurrency = Number.isInteger(amount) ? amount : amount.toFixed(9);
        }
      }
    },
    getAmountSuffix(value) {
      this.amountWidth = 0;
      const c = document.createElement('canvas');
      const context = c.getContext('2d');

      context.font = '700 24px Gilroy';

      const valueWidth = context.measureText(value).width;

      if (valueWidth < 377) this.amountWidth = valueWidth + 23;
    },
    setCurrency(currencyCode) {
      if (this.selectedAccount === currencyCode || !this.$refs.amountInput.validate()) return;

      this.selectedAccount = currencyCode;

      if (currencyCode === this.baseCurrencyCode) {
        this.send.amount *= this.getRate(this.selectedAccount);
      } else {
        this.send.amount /= this.getRate(this.selectedAccount);
      }

      this.getAmountSuffix(this.send.amount);
    },
    fetchKickUsers(search) {
      const filtered = this.mockUsers.filter(item => (item.fullName.toLowerCase()).includes(search.toLowerCase()));

      return new Promise(resolve => setTimeout(() => resolve(filtered), 500));
    },
    onRecipientChange(val) {
      if (typeof val === 'string') {
        this.send.onchain = true;
        this.getCurrencyCommission();
      } else {
        this.send.onchain = false;
      }
    },
    getCurrencyCommission() {
      const id = generateId();
      const payload = {
        id,
        type: 'currencyCommissionRequest',
        currencyCode: this.selectedAccount
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    getWalletAddress(currencyCode) {
      return this.$store.getters['user/getWalletAddress'](currencyCode);
    },
    lastVisit(dateTime) {
      const now = new Date();
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(dateTime);
      const minutes = date.getMinutes();
      const hours = date.getHours();
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      const diff = now - date;
      const diffHours = Math.floor(diff / 1000 / 60 / 60);

      if (diffHours < 1) return `last seen ${Math.floor(diff / 1000 / 60)} min ago`;

      if (diffHours >= 1 && diffHours < 24 && day === now.getDate()) return `last seen today at ${hours}:${minutes}`;

      if (year !== now.getFullYear()) return `last seen ${day} ${months[month]} ${year}`;

      return `last seen ${day} ${months[month]} at ${hours}:${minutes}`;
    },
    redirectToTransactions() {
      this.resetTransaction();
      this.$emit('goToTransactions');
    },
    editTransaction() {
      this.formSubmitted = false;
      this.transactionError = '';
    },
    resetTransaction() {
      this.editTransaction();

      this.confirmed = false;
      this.success = false;
      this.recipient = '';
      this.amountWidth = 0;
      this.send = {
        amount: '',
        onchain: false
      };
    },
    confirmTransaction() {
      this.loading = true;
      this.submitTransaction();
    },
    submitTransaction() {
      this.transactionError = '';

      const payload = {
        id: generateId(),
        type: 'withdrawRequest',
        currencyCode: this.selectedAccount,
        bcAddressTo: this.recipient,
        amount: this.send.amount
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);

      if (this.txError) {
        this.transactionError = this.txError;
        this.loading = false;
      }
    }
  },
  watch: {
    async search(val) {
      let users = [];
      this.isLoadingUsers = true;

      if (val && val.length > 1) users = await this.fetchKickUsers(val);

      this.isLoadingUsers = false;

      if (users.length) this.kickUsers = users;
    },
    reset(val) {
      if (val) this.resetTransaction();
    },
    selected(val) {
      this.selectedAccount = val;
      this.displayCurrency = val;
    },
    displayCurrency(val) {
      if (val === this.baseCurrencyCode) {
        const amount = this.send.amount * this.getRate(this.selectedAccount);
        this.send.amountInBaseCurrency = Number.isInteger(amount) ? amount : amount.toFixed(9);
      }

      this.getAmountSuffix(this.send[this.amountName]);
    },
    commission(val) {
      if (val) this.fee = val;
    },
    txHash(val) {
      if (val) {
        this.success = true;
        this.transactionHash = val;
        this.loading = false;
      }
    },
    txError(val) {
      if (val) this.transactionError = val;
    }
  },
  computed: {
    amountHint() {
      if (!this.send.amount) {
        return `Minimal amount > ${this.minAmount[this.selectedAccount]} ${this.selectedAccount}`;
      }

      if (this.selectedAccount === this.baseCurrencyCode) {
        return `${this.$n((+this.send.amount).toFixed(9))} ${this.baseCurrencyCode}`;
      }

      return `≈ ${this.$n((this.send.amount * this.getRate(this.selectedAccount)).toFixed(9))} ${this.baseCurrencyCode}`;
    },
    recipientHint() {
      if (typeof this.recipient === 'object') return this.$t('transactions.send.withoutFee');

      return '';
    },
    feeHint() {
      return `≈ ${this.$n((this.getRate(this.selectedAccount) * this.fee).toFixed(9))} ${this.baseCurrencyCode}`;
    },
    totalHint() {
      if (this.selectedAccount !== this.baseCurrencyCode) {
        return `≈ ${this.$n((this.getRate(this.selectedAccount) * this.send.amount).toFixed(9))} ${this.baseCurrencyCode}`;
      }
      return false;
    },
    amountName() {
      if (this.displayCurrency === this.baseCurrencyCode) return 'amountInBaseCurrency';

      return 'amount';
    },
    etherscanLink() {
      return `${process.env.VUE_APP_ETHERSCAN}${this.transactionHash}`;
    },
    btcLink() {
      return `${process.env.VUE_APP_BTC}${this.transactionHash}`;
    },
    bchLink() {
      return `${process.env.VUE_APP_BCH}${this.transactionHash}`;
    },
    ltcLink() {
      return `${process.env.VUE_APP_LTC}${this.transactionHash}`;
    },
    trxLink() {
      return `${process.env.VUE_APP_TRX}${this.transactionHash}`;
    },
    omniexplorerLink() {
      return `${process.env.VUE_APP_OMNIEXPLORER}${this.transactionHash}`;
    },
    accounts() {
      return this.$store.state.user.balance;
    },
    kickExWSOpen() {
      return this.$store.state.user.kickExWSOpen;
    },
    commission() {
      return this.$store.state.user.commission;
    },
    txHash() {
      return this.$store.state.user.txHash;
    },
    txError() {
      return this.$store.state.user.txError;
    },
    kickBalance() {
      return this.$store.getters['user/getAvailableBalance']('KICK');
    }
  },
  mounted() {
    if (!this.kickBalance) {
      this.selectedAccount = this.accounts[0].currencyCode;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .process {
    margin-top: 100px;

    &__title {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    &__block { margin-bottom: 30px; }

    &__side {
      font-weight: 600;
      font-size: 16px;
      padding-top: 15px;

      @media screen and (max-width: 600px) { width: 100%;}
    }

    &__input {
      max-width: 530px;
      position: relative;

      .selection {
        font-weight: 700;
        font-size: 24px;
        margin-top: 4px;
      }

      ::v-deep .v-input__slot { height: 70px !important; }

      ::v-deep .v-label {
        font-size: 24px;
      }

      ::v-deep .v-label--active {
        font-size: 16px;
        top: 0;
      }

      ::v-deep .v-text-field input {
        font-size: 24px !important;
        font-weight: 700 !important;
      }

      ::v-deep .input__currency .v-input__slot { padding-right: 100px !important; }

      &__value {
        position: absolute;
        z-index: 5;
        right: 10%;
        top: 18px;
        text-align: right;
        font-size: 16px;
        font-weight: 600;

        .base {
          font-size: 12px;
          color: $grey-text;
        }

        &.currencies {
          right: 18px !important;
          top: 25px;

          & > span {
            margin-left: 12px;
            color: $blue;
            cursor: pointer;

            &.active { color: $orange-3 !important; }
          }
        }
      }

      &__suffix {
        position: absolute;
        top: 21px;
        display: none;
        font-weight: 700;
        font-size: 24px;

        &::before {
          content: '';
          width: 2px;
          height: 26px;
          display: block;
          background-color: $blue;
          position: absolute;
          top: 4px;
          left: -6px;
        }

        &.active { display: inline-block; }
      }
    }

    &__error {
      margin: 25px 0;
      color: $error-color;
    }

    .main_button {
      margin: 0 !important;
      padding: 20px 80px !important;
      font-size: 18px !important;
    }

    &__confirm {
      background-color: $light-theme-line;
      padding: 8px 20px;
      line-height: 2;
      font-weight: 500;
      position: relative;
      max-width: 530px;
      word-wrap: break-word;

      &__currency {
        font-weight: 700;
        font-size: 24px;
        color: $sub-black;
      }

      &__edit {
        position: absolute;
        top: 15px;
        right: 20px;
        font-weight: 600;
        font-size: 16px;
        color: $blue;
        cursor: pointer;
      }

      &__total {
        color: $grey-text;
        font-weight: 500;
        line-height: 2;
      }
    }

    .verification {
      max-width: 560px;
    }

    .transaction {
      &__info {
        font-size: 16px;
        font-weight: 500;
        margin-top: 30px;
        min-width: 120px;

        & > span {
          display: block;
          font-size: 12px;
          color: $grey-text;
          font-weight: 600;
          margin-bottom: 10px;
        }

        @keyframes blink {
          50% { color: transparent; }
        };

        .loader__dot {
          animation: 1s blink infinite;
          font-size: 45px;
          line-height: 0;
          display: inline-block;
        }

        .loader__dot:nth-child(2) { animation-delay: 250ms; }
        .loader__dot:nth-child(3) { animation-delay: 500ms; }
      }

      &__btn {
        display: inline-block;
        padding: 20px 40px !important;
        max-width: 242px;

        &__block { margin-top: 50px; }

        & > a { text-decoration: none !important; }

        @media screen and (max-width: 670px) { margin-top: 20px !important; }
      }

      &__offchain {
        @media screen and (max-width: 670px) { flex-wrap: wrap; }
      }

      &__success {
        position: relative;

        &__block { max-width: 700px; }

        .etherscan__link {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;

          & > a {
            color: $blue !important;
            text-decoration: none;
          }

          & > svg {
            width: 13px;
            height: 12px;
            fill: $blue;
          }

          &__logo {
            width: 27px;
            height: 27px;
            margin-right: 5px;
            background-color: $white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
          }
        }
      }

      &__achievement {
        position: relative;
        width: 278px;
        height: 339px;
        background-color: $blue-lighter-transparent-3;
        text-align: center;
        padding: 23px 0;
        color: $grey-text;
        font-weight: 600;
        background-position: 50% 50%;
        background-size: 136px auto;
        margin-top: 20px;

        &__title {
          font-size: 20px;
          color: $grey-dark2;
          font-weight: 700;

          @media screen and (max-width: 670px) { font-size: 14px; }
        }

        &__link {
          position: absolute;
          width: 100px;
          bottom: 20px;
          right: calc(50% - 50px);
          font-size: 16px;
          color: $blue;
          font-weight: 600;
          text-decoration: none;
        }

        @media screen and (max-width: 670px) {
          width: 242px;
          height: 290px;
          background-size: 120px auto;
          font-size: 12px;
        }
      }
    }
  }

  .combobox {
    &__empty {
      text-align: left;
      padding-left: 13px;
     }

    &__user {
      display: flex;
      align-items: center;
      text-align: left;
    }

    &__avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__name {
      font-size: 16px;
      font-weight: 600;
      margin: 0 15px;

      & > span {
        display: block;
        color: $light-theme-text;
        font-size: 12px;
      }
    }

    &__wallet {
      font-size: 24px;
      font-weight: 700;
    }

    ::v-deep .v-select__selections { padding-top: 0 !important; }

    &.v-input--is-label-active {
      ::v-deep .v-label { display: none !important; }
    }

    ::v-deep input { margin: auto 0 !important; }

    ::v-deep .v-icon { display: none !important; }

    ::v-deep .v-select__slot {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ::v-deep.v-select-list a {
    margin: 8px 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .v-list__tile__avatar {
    justify-content: center;
    min-width: 35px;

    .v-avatar {
      height: 25px !important;
      width: auto !important;
      max-width: 30px !important;
      margin-right: 8px;
    }
  }

  .v-list__tile__title { margin-top: 5px; }

  .v-list__tile__avatar.default .v-avatar,
  .v-avatar.default {
    width: 25px !important;
    height: 25px !important;
    background: $blue;
  }

  ::v-deep .coin-icon {
    width: 30px;
    margin-right: 8px;
  }
</style>
