import { render, staticRenderFns } from "./WalletBuyProcess.vue?vue&type=template&id=f73d75da&scoped=true&lang=pug&"
import script from "./WalletBuyProcess.vue?vue&type=script&lang=js&"
export * from "./WalletBuyProcess.vue?vue&type=script&lang=js&"
import style0 from "./WalletBuyProcess.vue?vue&type=style&index=0&id=f73d75da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f73d75da",
  null
  
)

export default component.exports