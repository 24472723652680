<template lang="pug">
  .buy.wrapper
    v-form(ref="form", v-model="valid", @submit.prevent="")
      v-layout
        v-flex.buy__title(sm10, offset-sm2) {{ $t('actions.buyTokens') }}
      v-layout.buy__block(wrap)
        v-flex.buy__side(sm2) {{ $t('actions.iBuyCrypto') }}
        v-flex(sm10)
          v-layout.buy__input
            v-text-field.main_text_input(
              v-model="buyAmount",
              :rules="[rules.required, rules.amount, rules.minBuy, rules.maxBuy]",
              @keypress="handleKeypress($event, 'buyAmount')",
              @input="handleBuyAmountInput",
              outline)
            v-select.main_text_input.small(
              label="Currency",
              v-model="buyCurrency",
              :items="cryptoCurrenciesToExchange",
              :rules="[rules.required]",
              append-icon="keyboard_arrow_down",
              outline)
      v-layout.buy__block(wrap)
        v-flex.buy__side(sm2) {{ $t('actions.iSellFiat') }}
        v-flex(sm10)
          v-layout.buy__input
            v-text-field.main_text_input(
              v-model="sellAmount",
              :rules="[rules.required, rules.amount, rules.minSell, rules.maxSell]",
              :hint="rateHint",
              @keypress="handleKeypress($event, 'sellAmount')",
              @input="handleSellAmountInput",
              placeholder="0.00",
              persistent-hint,
              outline)
            v-select.main_text_input.small(
              label="Currency",
              v-model="sellCurrency",
              :items="fiatCurrencies",
              item-text="code",
              item-value="code",
              :rules="[rules.required]",
              append-icon="keyboard_arrow_down",
              outline)
      v-layout.buy__block(wrap)
        v-flex.buy__side(sm2)
          //- .buy__input
          //-   v-select.main_text_input(
          //-     label="Service",
          //-     v-model="selectedService",
          //-     :items="Object.keys(services)",
          //-     :rules="[rules.required]",
          //-     append-icon="keyboard_arrow_down",
          //-     outline)
          //-     template(slot="selection" slot-scope="data")
          //-       v-layout(align-end)
          //-         v-avatar(:class="{ default: !services[selectedService].logo }")
          //-           img(v-if="services[selectedService]", :src="services[selectedService].logo")
          //-         .selection {{ services[data.item].name }}
          //-     template(slot="item", slot-scope="data")
          //-       v-list-item-avatar(:class="{ default: !services[data.item].logo }")
          //-         img(v-if="services[data.item].logo", :src="services[data.item].logo")
          //-       v-list-item-content
          //-         v-list-item-title(v-html="services[data.item].name")
        v-layout(align-center, wrap)
          v-btn.main_button.buy__btn(:disabled="!valid", @click="requestTransactionUrl") Buy
          SvgCardsLogos
      v-layout.disclaimer
        v-flex.buy__side(sm2)
        v-flex(sm8)
          div(v-html="$t('common.mercuryoDisclaimer')")
    notifications(
      group="wallet",
      position="bottom right",
      classes="vue-notification")
</template>

<script>
import REGEXP from '@/regexp';
import services from '@/services';
import SvgCardsLogos from '@/components/svg/SvgCardsLogos';
import worker from '@/worker';
import { generateId } from '@/utils';
import { mapState } from 'vuex';

export default {
  components: { SvgCardsLogos },
  props: {
    selected: { type: String, required: true },
    reset: { type: Boolean, default: false }
  },
  data() {
    return {
      buyCurrency: this.selected,
      buyAmount: '',
      sellAmount: '',
      sellCurrency: '',
      services,
      selectedService: '1',
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        amount: v => REGEXP.float.test(v) || REGEXP.integer.test(v) || 'This value should be integer or float',
        minBuy: v => !this.minBuy || parseFloat(v) >= this.minBuy || `Minumum is ${this.minBuy}`,
        maxBuy: v => !this.maxBuy || parseFloat(v) <= this.maxBuy || `Maximum is ${this.maxBuy}`,
        minSell: v => !this.minSell || parseFloat(v) >= this.minSell || `Minumum is ${this.minSell}`,
        maxSell: v => !this.maxSell || parseFloat(v) <= this.maxSell || `Maximum is ${this.maxSell}`
      },
      intervalId: null,
      ratesInterval: 30000,
      allowedInput: '0123456789.',
      minBuy: null,
      maxBuy: null,
      minSell: null,
      maxSell: null
    };
  },
  computed: {
    ...mapState('user', [
      'currencies',
      'defaultFiatCurrency',
      'fiatCurrencies',
      'cryptoCurrenciesToExchange',
      'fiatRates',
      'fiatLimits'
    ]),
    rateHint() {
      if (!this.fiatRates || !this.fiatRates[this.buyCurrency]) return '';

      const rate = Number(this.fiatRates[this.buyCurrency][this.sellCurrency]).toFixed(2);

      return `1 ${this.buyCurrency} = ${this.$options.filters.currency(rate, this.$i18n.locale)} ${this.sellCurrency}`;
    },
    kickExWSOpen() { return this.$store.state.user.kickExWSOpen; },
    buyCurrencyPrecision() {
      const currency = this.currencies.find(i => i.currencyCode === this.buyCurrency);

      return currency ? currency.e : 4;
    }
  },
  watch: {
    defaultFiatCurrency(val) {
      if (val) this.sellCurrency = val;
    },
    selected(val) {
      if (val) this.buyCurrency = val;
    },
    buyCurrency(val) {
      if (!this.fiatRates || !this.sellCurrency || !this.fiatRates[val]) return;

      const sell = Number(this.buyAmount * this.fiatRates[val][this.sellCurrency]).toFixed(2);
      console.log('!!!!!!!!! buyCurrency', sell);
      this.sellAmount = !sell || +sell <= 0 ? '' : sell;
      this.setMinAndMax();
    },
    sellCurrency(val) {
      if (!this.fiatRates || !this.buyCurrency || !this.fiatRates[this.buyCurrency]) return;

      const sell = Number(this.buyAmount * this.fiatRates[this.buyCurrency][val]).toFixed(2);
      console.log('!!!!!!!!! sellCurrency', sell);
      this.sellAmount = !sell || +sell <= 0 ? '' : sell;
      this.setMinAndMax();
    },
    kickExWSOpen(val) {
      if (val) {
        this.getFiatCurrencies();
        this.getFiatRates();
        this.getFiatLimits();

        if (!this.intervalId) {
          this.intervalId = setInterval(this.getFiatRates, this.ratesInterval);
        }
      }
    },
    fiatRates: {
      handler(val) {
        const sell = Number(this.buyAmount * val[this.buyCurrency][this.sellCurrency]).toFixed(2);
        console.log('!!!!!!!!! fiatRates', sell);
        this.sellAmount = !sell || +sell <= 0 ? '' : sell;
      },
      deep: true
    },
    fiatLimits: {
      handler() {
        this.setMinAndMax();
      },
      deep: true
    }
  },
  methods: {
    getFiatRates() {
      const payload = {
        id: generateId(),
        type: 'getFiatRates'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    getFiatLimits() {
      const payload = {
        id: generateId(),
        type: 'getFiatLimits'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    handleKeypress(ev, propName) {
      const dotIdx = this[propName].indexOf('.');

      if (!this.allowedInput.includes(ev.key)
        || (ev.key === '.' && dotIdx !== -1)) {
        ev.preventDefault();
      }
    },
    handleBuyAmountInput(val) {
      if (Number.isNaN(parseFloat(val))) return;

      if (val.split('.')[1] && val.split('.')[1].length > this.buyCurrencyPrecision) {
        this.$nextTick(() => {
          this.buyAmount = `${val.split('.')[0]}.${val.split('.')[1].slice(0, this.buyCurrencyPrecision)}`;
        });
      }
      const sell = Number(parseFloat(val) * this.fiatRates[this.buyCurrency][this.sellCurrency]).toFixed(2);
      this.sellAmount = !sell || +sell <= 0 ? '' : sell;
    },
    handleSellAmountInput(val) {
      if (Number.isNaN(parseFloat(val))) return;

      if (val.split('.')[1] && val.split('.')[1].length > 2) {
        this.$nextTick(() => {
          this.sellAmount = `${val.split('.')[0]}.${val.split('.')[1].slice(0, 2)}`;
        });
      }

      this.buyAmount = Number(parseFloat(val) / this.fiatRates[this.buyCurrency][this.sellCurrency]).toFixed(4);
    },
    requestTransactionUrl() {
      if (!this.$refs.form.validate() || !this.kickExWSOpen) return;

      const payload = {
        id: generateId(),
        type: 'getFiatDepositUrl',
        cryptoCurrency: this.buyCurrency,
        cryptoAmount: this.buyAmount,
        fiatCurrency: this.sellCurrency,
        fiatAmount: this.sellAmount,
        ico: true
      };

      worker.myWorker.postMessage(payload);
    },
    setMinAndMax() {
      const limits = this.fiatLimits.find(i => i.from.currency === this.sellCurrency && i.to.currency === this.buyCurrency);

      if (limits) {
        this.minBuy = limits.to.min;
        this.maxBuy = limits.to.max;
        this.minSell = limits.from.min;
        this.maxSell = limits.from.max;
      }
    }
  },
  mounted() {
    if (this.kickExWSOpen && !this.intervalId) {
      this.getFiatRates();
      this.getFiatLimits();

      this.intervalId = setInterval(this.getFiatRates, this.ratesInterval);
    }

    if (this.defaultFiatCurrency) {
      this.sellCurrency = this.defaultFiatCurrency;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .buy {
    padding-top: 55px;
    text-align: left;

    &__title {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    &__side {
      font-weight: 600;
      font-size: 16px;
      padding-top: 15px;

      @media screen and (max-width: 600px) { width: 100%;}
    }

    &__input {
      position: relative;
      max-width: 530px;

      .main_text_input {
        &:not(.small) { margin-right: 10px !important; }

        &.small {
          width: 140px !important;
          min-width: 140px !important;
        }
      }

      .selection { font-size: 16px; }

      ::v-deep .v-input__slot { height: 70px !important; }

      ::v-deep .v-text-field input {
        font-size: 24px !important;
        font-weight: 700 !important;

        &::placeholder { color: $sub-black; }

        &:focus::placeholder { color: transparent; }
      }
    }

    .disclaimer {
      margin-top: 20px;
      font-size: 12px;
      line-height: 1.2;

      ::v-deep span { font-weight: 600; }
    }

    ::v-deep .v-btn.buy__btn {
      font-size: 18px !important;
      max-height: 60px !important;
      padding: 21px 85px !important;
      margin-right: 35px;
    }
  }

  .v-list__tile__avatar {
    justify-content: center;
    min-width: 35px;

    .v-avatar {
      height: 25px !important;
      width: auto !important;
      max-width: 30px !important;
      margin-right: 8px;
    }
  }

  .v-list__tile__avatar.default .v-avatar,
  .v-avatar.default {
    width: 25px !important;
    height: 25px !important;
    background: $blue;
  }

  .v-select__selections {
    .v-avatar {
      height: 25px !important;
      width: auto !important;
      max-width: 30px !important;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    .buy {
      padding-top: 28px;

      &__title {
        font-size: 28px;
        margin-bottom: 0;
        font-weight: 600;
      }

      &__btn {
        width: 100%;
      }

      .visa-mastercard {
        margin-top: 20px;
        width: 100%;
        justify-content: center;
      }

      .disclaimer {
        text-align: center;

        .buy__side.sm2 { display: none; }
      }

      ::v-deep .v-input__slot {
        padding-top: 4px !important;
      }

      ::v-deep .main_text_input.v-text-field input {
        font-size: 18px !important;
        font-weight: 600;
      }
    }
  }
</style>
