import askoin from '@/assets/services/askoin.png';
import simplex from '@/assets/services/simplex.png';

const services = {
  1: {
    id: 1,
    name: 'Askoin',
    order: 1,
    logo: askoin
  },
  2: {
    id: 2,
    name: 'Simplex',
    order: 2,
    logo: simplex
  }
};

export default services;
