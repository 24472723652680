<template lang="pug">
  .deposit.wrapper
    v-form#form(ref="form", v-model="valid", @submit.prevent="submitForm")
      v-layout
        v-flex.deposit__title(sm10, offset-sm2) Deposit tokens
      v-layout.deposit__block(wrap)
        v-flex.deposit__side(sm2) Tokens
        v-flex(sm10)
          .deposit__input
            v-select.main_text_input(
              label="Currency",
              v-model="selectedAccount",
              :items="filteredCurrencies",
              item-text="currencyCode",
              item-value="currencyCode",
              :rules="[rules.required]",
              append-icon="keyboard_arrow_down",
              hide-details,
              outline)
              template(slot="selection" slot-scope="data")
                CoinIcon(:name="data.item.currencyCode")
                .selection {{ data.item.currencyName }}
              template(slot="item", slot-scope="data")
                CoinIcon(:name="data.item.currencyCode")
                v-list-item-content
                  v-list-item-title(v-html="data.item.currencyName")
            .deposit__input__value
              .origin {{ getAvailableBalance(selectedAccount) | roundedNumber(currencyPrecision, $i18n.locale) }} {{ selectedAccount }}
              .base ≈ {{ getAvailableBalanceInUserCurrency(selectedAccount) | roundedNumber(2, $i18n.locale) }} {{ baseCurrencyCode }}
            v-btn-toggle.mb-3(
              v-if="blockchainMultiple"
              v-model="blockchainId",
              color="primary",
              @change="requestAddress(selectedAccount)",
              mandatory)
              v-btn.toggle(v-for="(val, key) in availableBlockchains", :key="key", :value="key") {{ val }}
            v-btn-toggle.mb-3(
              v-if="selectedAccount === 'BTC'",
              v-model="isLegacy",
              color="primary",
              @change="requestAddress(selectedAccount)",
              mandatory)
              v-btn.toggle(:value="false") Segwit (recommended)
              v-btn.toggle(:value="true") Legacy

      v-layout.deposit__block(wrap)
        v-flex.deposit__side(sm2) Address
        v-flex(sm10)
          .deposit__disabled
            v-text-field.main_text_input(
              :value="selectedAddress.address",
              ref="address",
              readonly,
              disabled,
              solo,
              flat,
              hide-details)
            .deposit__disabled__link(v-if="selectedAddress.address", slot="activator", @click="copyToClipboard('address')") Copy

      v-layout.deposit__block.mt-3(wrap, v-if="selectedAddress.tag")
        v-flex.deposit__side(sm2) Tag
        v-flex(sm10)
          .deposit__disabled
            v-text-field.main_text_input(
              :value="selectedAddress.tag",
              ref="tag",
              readonly,
              disabled,
              solo,
              flat,
              hide-details)
            .deposit__disabled__link(v-if="selectedAddress.tag", slot="activator", @click="copyToClipboard('tag')") Copy

      v-layout.deposit__block(wrap)
        v-flex.deposit__side(sm2)
        v-flex(sm10)
          .deposit__qr
            qrcode-vue(v-if="selectedAddress", :value="selectedAddress.address", :size="177", level="H")
          v-btn.main_button.deposit__close(@click="$emit('clickClose')") Close
    notifications(
      group="wallet",
      position="bottom right",
      classes="vue-notification")
</template>

<script>
import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';
import { mapState } from 'vuex';
import worker from '@/worker';
import { generateId } from '@/utils';
import CoinIcon from '@/components/CoinIcon';

export default {
  components: { QrcodeVue, CoinIcon },
  props: {
    baseCurrencyCode: { type: String, required: true },
    selected: { type: String, required: true },
    reset: { type: Boolean, default: false }
  },
  data() {
    return {
      valid: false,
      selectedAccount: null,
      rules: {
        required: v => !!v || 'Required.'
      },
      blockchainId: null,
      blockchainMultiple: false,
      availableBlockchains: {},
      isLegacy: false
    };
  },
  methods: {
    requestAddress(currencyCode) {
      if (!currencyCode || !this.blockchainId) return;

      const payload = {
        id: generateId(),
        type: 'getDepositAddress2',
        currencyCode,
        blockchainId: parseInt(this.blockchainId, 10)
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    copyToClipboard(input) {
      const value = input === 'tag' ? this.$refs.tag.value : this.$refs.address.value;
      const el = document.createElement('textarea');

      el.setAttribute('readonly', '');
      el.id = 'myTextArea';
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      el.value = value;
      document.querySelector('#form').appendChild(el);
      el.select();

      let success = false;

      try {
        success = document.execCommand('copy');
      } catch (err) {
        console.log(err);
      }

      if (success) {
        Vue.notify({
          group: 'wallet',
          title: `${this.selectedAccount} ${input} copied to the clipboard`,
          duration: 500
        });
      }

      document.querySelector('#form').removeChild(el);

      return success;
    },
    getAvailableBalance(currencyCode) {
      return this.$store.getters['user/getAvailableBalance'](currencyCode);
    },
    getAvailableBalanceInUserCurrency(currencyCode) {
      return this.$store.getters['user/getAvailableBalanceInUserCurrency'](currencyCode);
    }
  },
  computed: {
    ...mapState('user', ['currencies', 'addresses']),
    kickExWSOpen() {
      return this.$store.state.user.kickExWSOpen;
    },
    selectedAddress() {
      if (Array.isArray(this.addresses[this.selectedAccount])) {
        const item = this.addresses[this.selectedAccount].find(i => (this.isLegacy ? (i.type === 'legacy') : (i.type !== 'legacy')));

        return item || '';
      }

      return this.addresses[this.selectedAccount] || { address: '', tag: '' };
    },
    currencyPrecision() {
      const currency = this.currencies.find(i => i.currencyCode === this.selectedAccount);

      return currency ? currency.e : 2;
    },
    filteredCurrencies() {
      return this.currencies.filter(c => c.blockchainIds);
    }
  },
  watch: {
    selected(val) {
      this.selectedAccount = val;
    },
    selectedAccount(val) {
      if (!val) return;

      const selected = this.filteredCurrencies.find(i => i.currencyCode === val);
      this.availableBlockchains = {};

      if (selected && selected.currencyCode === 'BTC') {
        this.blockchainMultiple = false;
      } else {
        this.blockchainMultiple = true;

        selected.blockchainIds.forEach((el, idx) => {
          this.availableBlockchains[el] = selected.blockchainNames[idx];
        });
      }

      this.blockchainId = selected.blockchainIds[0];

      if (!this.addresses[val]) this.requestAddress(val);
    },
    addresses: {
      handler(val) {
        return val;
      },
      deep: true
    }
  },
  mounted() {
    this.selectedAccount = this.selected;
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .deposit {
    padding-top: 55px;
    text-align: left;

    &__title {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    &__side {
      font-weight: 600;
      font-size: 16px;
      padding-top: 15px;

      @media screen and (max-width: 600px) { width: 100%;}
    }

    &__input {
      position: relative;
      max-width: 530px;

      .selection {
        font-weight: 700;
        font-size: 24px;
        margin-top: 4px;
        color: $sub-black;
      }

      ::v-deep .v-input__slot { height: 70px !important; }

      ::v-deep .v-label {
        font-size: 24px;
        top: 24px;
      }

      ::v-deep .v-label--active {
        font-size: 16px;
        top: 0;
      }

      ::v-deep .v-text-field input {
        font-size: 24px !important;
        font-weight: 700 !important;
      }

      ::v-deep .input__currency .v-input__slot { padding-right: 100px !important; }

      &__value {
        position: absolute;
        z-index: 5;
        right: 10%;
        top: 18px;
        text-align: right;
        font-size: 16px;
        font-weight: 600;

        .base {
          font-size: 12px;
          color: $grey-text;
        }

        &.currencies {
          right: 18px !important;
          top: 25px;

          & > span {
            margin-left: 12px;
            color: $blue;
            cursor: pointer;

            &.active { color: $orange-3 !important; }
          }
        }
      }
    }

    &__disabled {
      max-width: 530px;
      position: relative;

      &__link {
        position: absolute;
        top: 24px;
        right: 4%;
        color: $blue;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
      }

      ::v-deep .v-text-field input { font-weight: 500 !important; }

      ::v-deep .v-input__slot {
        height: 70px !important;
        background-color: $light-theme-line !important;
        border: 0 !important;
        padding-right: 70px !important;
      }
    }

    &__close {
      font-size: 18px !important;
      max-height: 60px !important;
      margin-left: 0 !important;
      margin-top: 20px !important;
      padding: 21px 65px !important;
    }

    &__qr { margin: 40px 0; }
  }

  .v-list__tile__avatar {
    justify-content: center;
    min-width: 35px;

    .v-avatar {
      height: 25px !important;
      width: auto !important;
      max-width: 30px !important;
      margin-right: 8px;
    }
  }

  .v-list__tile__title { margin-top: 5px; }

  .v-list__tile__avatar.default .v-avatar,
  .v-avatar.default {
    width: 25px !important;
    height: 25px !important;
    background: $blue;
  }

  ::v-deep .coin-icon {
    width: 30px;
    margin-right: 8px;
  }

  @media screen and (max-width: 600px) {
    .deposit {
      padding-top: 28px;

      &__title {
        font-size: 28px;
        margin-bottom: 0;
        font-weight: 600;
      }

      .deposit__qr { text-align: center; }

      ::v-deep canvas {
        width: 220px !important;
        height: 220px !important;
      }

      .main_button {
        width: 100%;
        margin-top: 0 !important;
      }

      .deposit__input .selection { font-size: 18px !important; }
    }
  }
</style>
