<template lang="pug">
  v-flex
    .wallet__menu
      .wrapper
        v-tabs(slot="extension", v-model="tab", height="77px", color="transparent")
          v-tabs-slider
          v-tab(to="accounts")
            span Accounts
          v-tab(to="transactions")
            span Transactions
        transition(name="fade", mode="out-in")
          .wallet__menu__filters(v-show="$router.currentRoute.name === 'WalletTransactions'")
            v-select.main_select(
              v-model="sort",
              :items="sortOptions",
              item-text="name",
              item-value="val",
              single-line,
              @change="val => $emit('orderChange', val)")
            .search
              v-icon(size="28") search
              span Search
</template>

<script>

export default {
  data() {
    return {
      tab: null,
      sort: 1,
      sortOptions: [
        { val: 1, name: 'Newest first' },
        { val: 2, name: 'Oldest first' }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .wallet__menu {
    position: relative;

    .wrapper { position: relative; }

    &::before {
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: $light-theme-line;
      content: '';
    }

    .v-tabs {
      &__slider {
        background: $orange !important;
        border-color: $orange !important;
      }

      &__container::before {
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $light-theme-line;
        content: '';
      }

      &__item {
        color: $sub-black;
        opacity: 1;
        span { padding: 0 10px; }

        &--active span {
          color: $orange;
          transition: all .3s $easing;
        }
      }

      &__div {
        text-transform: none;
        color: $sub-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        transition: all .3s $easing;
        margin-right: 56px;

        @media screen and (max-width: 1024px) {
          margin-right: 10px;
          font-size: 14px;
        }
      }
    }

    &__filters {
      position: absolute;
      top: 6px;
      right: 20px;
      color: $grey-text;
      font-size: 16px;
      font-weight: 600;

      .main_select { display: inline-block; }

      ::v-deep .v-select .v-select__selections {
        color: $grey-text;
        font-weight: 600;
        width: 155px;
      }

      .search {
        display: inline-block;
        cursor: pointer;

        .v-icon {
          color: $grey-background;
          margin-right: 9px;
          line-height: .7;
        }
      }

      @media screen and (max-width: 600px) {
        left: 20px;
        bottom: -80px;
        right: auto;
        top: auto;
      }
    }
  }
</style>
