<template lang="pug">
  .wallet
    transition(name="fade", mode="out-in")
      keep-alive
        router-view(
          @buttonClick="getDialog",
          :newestFirst="newestFirst",
          :baseCurrencyCode="baseCurrencyCode")
    v-dialog(v-model="showDialog", fullscreen, transition="dialog-bottom-transition")
      v-card
        .dialog
          v-layout.dialog__menu
            .wrapper
              .dialog__logo(@click="closeDialog")
                Logo(serviceName="wallet", :logoSize="35", :fontSize="20")
              .dialog__close(@click="closeDialog")
                SvgPlus
              v-tabs(v-model="depositTab", height="77px", color="transparent")
                v-tab
                  span {{ $t('common.crypto') }}
                v-tab(v-if="cryptoToExchange.includes(selectedAccount) && kyc_status === 3")
                  span {{ $t('common.fiat', { currency: selectedAccount }) }}
          v-tabs-items(:value="depositTab")
            v-tab-item(transition="fade-transition", reverse-transition="fade-transition")
              WalletDeposit(
                :selected="selectedAccount",
                :baseCurrencyCode="baseCurrencyCode",
                @clickClose="closeDialog")
            v-tab-item(transition="fade-transition", reverse-transition="fade-transition")
              WalletBuyProcess(
                :selected="selectedAccount",
                @clickClose="closeDialog")
</template>

<script>
import WalletCover from '@/components/WalletCover';
import WalletMenu from '@/components/WalletMenu';
import SvgPlus from '@/components/svg/SvgPlus';
import Logo from '@/components/Logo';
import WalletSendProcess from '@/components/WalletSendProcess';
import WalletDeposit from '@/components/WalletDeposit';
import WalletBuyProcess from '@/components/WalletBuyProcess';
import { mapState } from 'vuex';

export default {
  components: {
    WalletCover,
    WalletMenu,
    SvgPlus,
    Logo,
    WalletSendProcess,
    WalletDeposit,
    WalletBuyProcess
  },
  data() {
    return {
      showDialog: false,
      dialogType: '',
      selectedAccount: '',
      dialogReset: false,
      newestFirst: true,
      baseCurrencyCode: 'USD',
      depositTab: 0
    };
  },
  computed: {
    ...mapState('user', ['kyc_status']),
    cryptoToExchange() {
      return this.$store.state.user.cryptoCurrenciesToExchange;
    }
  },
  methods: {
    getDialog(dialogType, currencyCode) {
      this.showDialog = true;
      this.dialogType = dialogType;
      this.selectedAccount = currencyCode;
    },
    showTransactions() {
      this.closeDialog();
      this.$router.push('transactions');
    },
    closeDialog() {
      this.showDialog = false;
      this.dialogReset = true;
      this.selectedAccount = '';
    },
    reorderTransactions(order) {
      this.newestFirst = order === 1;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .fade-enter-active,
  .fade-leave-active {
    transition: all .3s $easing;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .dialog {
    text-align: left;
    padding: 0 0 40px;

    &__menu {
      position: sticky;

      &::before {
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $light-theme-line;
        content: '';
      }

      .wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: auto;
        max-width: 1140px;
        padding: 0 20px;

        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          padding-top: 20px;
        }
      }
    }

    &__close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      opacity: .54;
      position: absolute;
      right: 41px;
      top: 28px;
      cursor: pointer;
      z-index: 10;
    }

    &__logo {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      z-index: 10;
      margin-right: 100px;

      & > svg {
        width: 40px;
        height: 40px;
        margin-right: 13px;
      }
    }

    @media screen and (max-width: 600px) {
      .dialog {
        &__close { top: 20px; }

        &__logo {
          ::v-deep svg {
            width: 24px !important;
            height: 24px !important;
          }

          ::v-deep .descriptor {
            font-size: 15px !important;
          }
        }
      }

      ::v-deep .v-tabs {
        margin-top: 15px !important;

        .v-slide-group__prev.v-slide-group__prev--disabled { display: none !important; }
      }
    }
  }
</style>
